import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"

const NotFoundPage = () => (
  <Layout>
    <h1>Not Found</h1>
    <h2>
      <Link to="/">Head Home</Link>
    </h2>
  </Layout>
)

export const Head = () => <title>Not Found (404)</title>

export default NotFoundPage
